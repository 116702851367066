import React from "react";
import { graphql } from "gatsby";
import { CategoryContainer } from "../../ui/components";

export default ({ data }) => {
  const categories = data.categories.nodes;
  const category = data.contentfulKnowledgeHubCategory;
  const articles = data.articles.nodes;
  const tags = data.tags.nodes;

  return (
    <CategoryContainer
      categories={categories}
      category={category}
      articles={articles}
      tags={tags}
    />
  );
};

export const pageQuery = graphql`
  query KHCategoryQuery(
    $contentful_id: String!
    $locale: String!
    $language: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    articles: allContentfulKnowledgeHubArticle(
      sort: { fields: [publishedDate, createdAt], order: DESC }
      filter: {
        localContent: { slug: { ne: null } }
        category: {
          node_locale: { eq: $locale }
          contentful_id: { eq: $contentful_id }
        }
      }
    ) {
      totalCount
      nodes {
        tags {
          name
          contentful_id
        }
        ...KnowledgeHubArticleCard
        tags {
          name
          slug
        }
      }
    }

    tags: allContentfulKnowledgeHubTag(
      filter: {
        node_locale: { eq: $locale }
        knowledgehubarticle: {
          elemMatch: { category: { contentful_id: { eq: $contentful_id } } }
        }
      }
    ) {
      nodes {
        id
        name
        slug
        knowledgehubarticle {
          localContent {
            id
          }
        }
      }
    }
    categories: allContentfulKnowledgeHubCategory(
      filter: {
        featureInNews: { ne: true }
        node_locale: { eq: $locale }
        # knowledgehubarticle: {
        #   elemMatch: { localContent: { slug: { ne: null } } }
        # }
      }
    ) {
      nodes {
        title
        contentful_id
        seoMeta {
          slug
          title
        }
        knowledgehubarticle {
          localContent {
            id
          }
        }
      }
    }
    contentfulKnowledgeHubCategory(
      node_locale: { eq: $locale }
      contentful_id: { eq: $contentful_id }
    ) {
      title
      seoMeta {
        slug
        title
      }
    }
  }
`;
